export function toLocalizedEuro(
  priceInEuroCent: number | undefined | null,
  options?: { format?: string | undefined }
): string {
  if (priceInEuroCent === undefined || priceInEuroCent === null) {
    return ''
  }
  const priceInEuro = priceInEuroCent / 100
  return new Intl.NumberFormat(options?.format ?? 'de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(priceInEuro)
}
