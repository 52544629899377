import type { SalesType } from '@twlvxtwlv/types'
import { AuctionIcon } from './auction'
import { SellIcon } from './sell'

const sizes = {
  sm: 6,
  md: 8,
  lg: 12,
}

interface SalesTypeIconProps {
  salesType: SalesType
  size?: 'sm' | 'md' | 'lg'
}

export function SalesTypeIcon({ salesType, size = 'sm' }: SalesTypeIconProps) {
  switch (salesType) {
    case 'airdrop':
      return null
    case 'auction':
      return <AuctionIcon boxSize={sizes[size]} />
    case 'standard':
      return <SellIcon boxSize={sizes[size]} />
    default:
      return null
  }
}
