import { Box, Center, Heading } from '@chakra-ui/react'
import { toProductWithAuctionList } from '@twlvxtwlv/mapping'
import type { AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import type { ProductWithAmount } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { ProductCard } from '../cards'
import { LoadingSkeleton } from './loading-skeleton/loading-skeleton'

interface ProductCollectionProps {
  auctionsWithHighestBid?: AuctionWithHighestBid[] | null | undefined
  isLoadingMoreProducts?: boolean
  isLoadingProducts?: boolean
  numberOfPlaceholders: number
  productsWithAmount: ProductWithAmount[]
}

export function ProductCollection({
  auctionsWithHighestBid,
  isLoadingMoreProducts = false,
  isLoadingProducts = true,
  numberOfPlaceholders,
  productsWithAmount,
}: ProductCollectionProps) {
  const { t } = useTranslation('nft-gallery')

  const productWithAuctionList = useMemo(() => {
    return toProductWithAuctionList(productsWithAmount, auctionsWithHighestBid) ?? []
  }, [productsWithAmount, auctionsWithHighestBid])

  return (
    <>
      <LoadingSkeleton isVisible={isLoadingProducts} numberOfPlaceholders={numberOfPlaceholders} />
      <>
        {!isLoadingProducts ? (
          <>
            {productWithAuctionList.length > 0 ? (
              productWithAuctionList.map((pwa) => {
                return (
                  <Box key={pwa.product.code}>
                    <ProductCard
                      auctionWithHighestBid={pwa.auctionWithHighestBid}
                      product={pwa.product}
                      href={`/nft/${pwa.product.code}`}
                    />
                  </Box>
                )
              })
            ) : (
              <Center w="full">
                <Heading>{t('no-products-found')}</Heading>
              </Center>
            )}
          </>
        ) : null}
      </>
      <LoadingSkeleton
        isVisible={isLoadingMoreProducts}
        numberOfPlaceholders={numberOfPlaceholders}
      />
    </>
  )
}
