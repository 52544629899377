export const smartContractAddresses = {
  Old: '0x79DD97111b5eDbF4e37a985B872733CB2B116fEd',
  New: '0x112320aef9e36fA79b64Da4a9Fc1942c6dEBbB6E',
} as const

export type SmartContractAddress =
  (typeof smartContractAddresses)[keyof typeof smartContractAddresses]

export function toSmartContractAddress(value: string) {
  if (value === smartContractAddresses.New) {
    return smartContractAddresses.New
  } else if (value === smartContractAddresses.Old) {
    return smartContractAddresses.Old
  } else {
    return null
  }
}
