import { Flex } from '@chakra-ui/react'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

interface VideoProps {
  url: string | undefined | null
  autoStart?: boolean
  isLooping?: boolean
}

export function Video({ url, autoStart = false, isLooping = false }: VideoProps) {
  if (!url || url.trim().length === 0) {
    return null
  }
  return (
    <Flex justify="center" h="full" w="full" bg="gray.900">
      <ReactPlayer
        height="100%"
        width="100%"
        playsInline
        muted={autoStart}
        playing={autoStart}
        loop={isLooping}
        controls={false}
        url={url}
        config={{ file: { attributes: { playsInline: true } } }}
      />
    </Flex>
  )
}
