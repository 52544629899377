import { Flex, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import type { Product, ProductVariant } from '@twlvxtwlv/tokenforge'
import { getPriceInEurocentsForProduct } from '@twlvxtwlv/tokenforge'
import { toLocalizedEuro } from '@twlvxtwlv/utils/localization'
import { getTokenAvailabilityText } from '@twlvxtwlv/utils/token'
import { useTranslation } from 'next-i18next'

interface ProductsLeftStatisticsProps {
  product: Product | null
  showAmount: boolean
}

export function ProductsLeftStatistics({ product, showAmount }: ProductsLeftStatisticsProps) {
  const { t } = useTranslation('nft-details')

  let priceInEuroCents = 0
  const variant: ProductVariant | undefined | null = product?.defaultVariant
  if (variant) {
    priceInEuroCents = getPriceInEurocentsForProduct(product)
  }
  const tokenAvailabilityText = getTokenAvailabilityText(
    variant?.onHand,
    variant?.totalSupply,
    true
  )
  const rightValue = toLocalizedEuro(priceInEuroCents)

  return (
    <Flex w="full" justify="space-between">
      <SimpleGrid columns={2} spacing={6} alignItems="flex-start" w="full">
        <Stack align="start" spacing={0} gap={0.5}>
          <>
            {showAmount ? (
              <>
                <Text fontWeight="600" fontSize={['sm', 'sm', 'sm', 'sm', 'sm']} color="black">
                  {tokenAvailabilityText}
                </Text>
                <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
                  {t('tokens-left')}
                </Heading>
              </>
            ) : null}
          </>
        </Stack>
        <Stack align="end" spacing={0} gap={0.5}>
          <Text fontWeight="600" fontSize={['sm', 'sm', 'sm', 'sm', 'sm']} color="black">
            {rightValue}
          </Text>
          <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
            {t('price')}
          </Heading>
        </Stack>
      </SimpleGrid>
    </Flex>
  )
}
