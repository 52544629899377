import { AspectRatio, Box } from '@chakra-ui/react'
import type { Product } from '@twlvxtwlv/tokenforge'
import { getMediaFileUrl } from '@twlvxtwlv/tokenforge'
import { Thumbnail } from './thumbnail'
import type { MediaFileSizeType } from '@twlvxtwlv/types'

interface ImageProps {
  product: Product
  size?: MediaFileSizeType
  imageSize?: string
}

export function ProductImage({ product, size = 's-small', imageSize = '200px' }: ImageProps) {
  const imageUrl = getMediaFileUrl(product, 'gallery', size)
  const imageAlt = `${product?.name} hero image`

  return (
    <Box w="full" h="full">
      <AspectRatio w="full" ratio={1}>
        {imageUrl ? (
          <Thumbnail imageUrl={imageUrl} imageAlt={imageAlt} imageSize={imageSize} />
        ) : (
          <Box h="full" w="full" bg="white" />
        )}
      </AspectRatio>
    </Box>
  )
}
