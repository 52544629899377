import { AspectRatio, Box, Divider, Flex, Stack } from '@chakra-ui/react'

/**
 * Check ProductCard component for layout reference
 *
 */
export function LoadingSkeletonCard() {
  return (
    <Stack
      h="full"
      align="flex-start"
      justify="space-between"
      borderWidth="1px"
      borderColor="black"
      spacing={0}
      gap={0}
    >
      <AspectRatio w="full" ratio={1}>
        <Box w="full" bg="gray.300" />
      </AspectRatio>
      <Flex w="full" align="center" justify="space-between" px={1} py={1} flexGrow={0}>
        <Box h="24px" />
      </Flex>

      <Divider size="1" borderColor="#000" opacity={100} />

      <Stack
        divider={<Divider size="1" borderColor="#000" opacity={100} />}
        px={1}
        py={1}
        gap={0.5}
        spacing={0}
        flexGrow={1}
        pb={1}
        w="full"
        h="full"
      >
        <Box h="38px" />
        <Box h="56px" />
        <Box h="38px" />
      </Stack>
    </Stack>
  )
}
