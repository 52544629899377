import { Divider, Link, Stack } from '@chakra-ui/react'
import type { AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import { getProductType, toPublicMediaFileUri, type ProductWithAmount } from '@twlvxtwlv/tokenforge'
import NextLink from 'next/link'
import { Artist } from './artist'
import { Availability } from './availability'
import { ProductImage } from './image'
import { Name } from './name'
import { Statistics } from './statistics'

interface ProductCardProps {
  product: ProductWithAmount
  href: string
  auctionWithHighestBid?: AuctionWithHighestBid | null | undefined
}

export function ProductCard({ auctionWithHighestBid, product, href }: ProductCardProps) {
  const productType = getProductType(product)

  const isAwardProduct = productType === 'award'
  const isErc721AwardProduct = isAwardProduct && !product.tokenId

  return (
    <Link
      as={NextLink}
      href={!isErc721AwardProduct ? href : `${href}/${product.productVariant?.id.split('/').pop()}`}
      _hover={{ textDecoration: 'none' }}
    >
      <Stack
        h="full"
        w="full"
        align="flex-start"
        justify="space-between"
        borderWidth="1px"
        borderColor="black"
        spacing={0}
        gap={0}
      >
        <ProductImage product={product} />
        <Availability
          auctionWithHighestBid={auctionWithHighestBid ?? undefined}
          product={product}
        />
        <Divider size="1" borderColor="#000" opacity={100} />
        <Stack
          divider={<Divider size="1" borderColor="#000" opacity={100} />}
          px={1}
          py={1}
          gap={1}
          spacing={0}
          flexGrow={1}
          w="full"
        >
          <Artist
            artistName={product?.creatorProfileName ?? ''}
            avatarUrl={toPublicMediaFileUri(product?.creatorAvatarImage, 's-thumb') ?? ''}
            showAwardText={isAwardProduct}
          />
          <Name productName={product?.name ?? ''} />
          <Statistics
            auctionWithHighestBid={auctionWithHighestBid}
            product={product}
            productType={productType}
          />
        </Stack>
      </Stack>
    </Link>
  )
}
