import { Button, ButtonGroup } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

interface LoadMoreButtonProps {
  isLoadingMore: boolean
  isVisible: boolean
  onClick: () => void
}

export function LoadMoreButton({ isLoadingMore, isVisible, onClick }: LoadMoreButtonProps) {
  const { t } = useTranslation('common')
  return (
    <>
      {isVisible ? (
        <ButtonGroup w="full" justifyContent="center">
          <Button variant="txtWhite" onClick={onClick} isLoading={isLoadingMore}>
            {t('load-more-items')}
          </Button>
        </ButtonGroup>
      ) : null}
    </>
  )
}
