import { Heading, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

interface NameProps {
  productName: string
}

export function Name({ productName }: NameProps) {
  const { t } = useTranslation('nft-details')

  return (
    <Stack flexGrow={1}>
      <Stack align="start" flex={1} w="full" spacing={0} gap={0.5}>
        <Text
          fontWeight="bold"
          color="black"
          fontSize="xs"
          noOfLines={2}
          height="2.4rem"
          lineHeight="1.2rem"
        >
          {productName}
        </Text>
        <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
          {t('nft-name')}
        </Heading>
      </Stack>
    </Stack>
  )
}
