import type { AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import type { ProductWithAmount } from '@twlvxtwlv/tokenforge'
import type { ProductType } from '@twlvxtwlv/types'
import { AuctionStatistics } from './auction-statistics'
import { AwardOwnedStatistics } from './awards-owned-statistics'
import { ProductsLeftStatistics } from './products-left-statistics'
import { ProductsOwnedStatistics } from './products-owned-statistics'

interface StatisticsProps {
  auctionWithHighestBid?: AuctionWithHighestBid | null | undefined
  product: ProductWithAmount
  productType: ProductType
}

export function Statistics({ auctionWithHighestBid, product, productType }: StatisticsProps) {
  const isTicketProduct = productType === 'ticket'
  const isAwardProduct = productType === 'award'
  const isClaimOnlyProduct = productType === 'claimOnly'

  return (
    <>
      {auctionWithHighestBid ? (
        <AuctionStatistics auctionWithHighestBid={auctionWithHighestBid} />
      ) : product.amount && product.tokenId ? (
        <ProductsOwnedStatistics product={product} />
      ) : product.amount && !product.tokenId && (isAwardProduct || isClaimOnlyProduct) ? (
        <AwardOwnedStatistics product={product} />
      ) : (
        <ProductsLeftStatistics product={product} showAmount={!isTicketProduct} />
      )}
    </>
  )
}
