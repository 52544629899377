import type { MigrationToken } from '@twlvxtwlv/types'
import { ethers } from 'ethers'
import { logger } from '../logging'
import { getAbiFunction } from './get-abi-function'
import {
  smartContractAddresses,
  toSmartContractAddress,
  type SmartContractAddress,
} from './smart-contract-address'

function getInfuraProvider() {
  return new ethers.providers.InfuraProvider('matic', {
    projectId: process.env.INFURA_PROJECT_ID,
    projectSecret: process.env.INFURA_PROJECT_SECRET,
  })
}

async function getGasPrice(): Promise<ethers.BigNumber> {
  const provider = getInfuraProvider()
  return await provider.getGasPrice()
}

async function getLicenceRocksPrivateWallet() {
  if (!process.env.LICENSE_ROCKS_PRIVATE_KEY) {
    throw new Error('Could not find private magic key while trying to get licence rocks wallet.')
  }
  return new ethers.Wallet(process.env.LICENSE_ROCKS_PRIVATE_KEY, await getInfuraProvider())
}

async function transferSingleToken(
  fromAddress: string,
  toAddress: string,
  tokenId: number,
  tokenAmount: number,
  contractAddress: string,
  abi: string[]
) {
  logger.info(
    `Transfer single token with contract address ${contractAddress}, with to address ${toAddress}, from address ${fromAddress}, token ID ${tokenId}, token amount ${tokenAmount} and abi ${abi}`
  )

  const wallet = await getLicenceRocksPrivateWallet()
  const contract = new ethers.Contract(contractAddress, abi, wallet)
  const gasPrice = await getGasPrice()
  const nullByteArray = ethers.utils.formatBytes32String('')

  return await contract.functions.safeTransferFrom(
    fromAddress,
    toAddress,
    tokenId,
    tokenAmount,
    nullByteArray,
    {
      gasPrice,
      gasLimit: 1000000,
    }
  )
}

async function batchTransferTokens(
  fromAddress: string,
  toAddress: string,
  contractAddress: string,
  abi: string[],
  tokens: MigrationToken[]
) {
  const tokenIds = []
  const tokenAmounts = []
  for (const token of tokens) {
    if (!token.tokenId || !token.amount) {
      throw Error(
        `Error while transferring batch tokens with contract address ${contractAddress}, with to address ${toAddress}, from address ${fromAddress}, because token with name ${token.name} is missing the token ID ${token.tokenId} or the token amount ${token.amount}!`
      )
    }
    tokenIds.push(token.tokenId)
    tokenAmounts.push(token.amount)
  }

  logger.info(
    `Transfer batch tokens with contract address ${contractAddress}, with to address ${toAddress}, from address ${fromAddress}, token IDs ${tokenIds} and abi ${abi}`
  )

  const wallet = await getLicenceRocksPrivateWallet()
  const contract = new ethers.Contract(contractAddress, abi, wallet)
  const gasPrice = await getGasPrice()
  const nullByteArray = ethers.utils.formatBytes32String('')

  return await contract.functions.safeBatchTransferFrom(
    fromAddress,
    toAddress,
    tokenIds,
    tokenAmounts,
    nullByteArray,
    {
      gasPrice,
      gasLimit: 1000000,
    }
  )
}

function getTokenAvailabilityText(
  availableNftCount: number | undefined,
  totalNftCount: number | undefined | null,
  isProductForSale: boolean
): string {
  if ((availableNftCount && !totalNftCount) || !isProductForSale) {
    return `${availableNftCount}`
  } else if (totalNftCount) {
    return `${availableNftCount}/${totalNftCount}`
  } else {
    return '0'
  }
}

export {
  batchTransferTokens,
  getAbiFunction,
  getGasPrice,
  getInfuraProvider,
  getTokenAvailabilityText,
  type SmartContractAddress,
  toSmartContractAddress,
  smartContractAddresses,
  transferSingleToken,
}
