import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const SellIcon: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Sell',
  viewBox: '0 0 162 162',
  path: (
    <>
      <circle cx="81" cy="81" r="80.5" fill="black" stroke="black" />
      <path
        d="M64.7932 44.8027C59.2418 44.8027 54.6895 49.3551 54.6895 54.9065C54.6895 60.4579 59.2418 65.0103 64.7932 65.0103C70.3447 65.0103 74.897 60.4579 74.897 54.9065C74.8962 49.3543 70.3447 44.8027 64.7932 44.8027ZM64.7932 59.4581C62.2398 59.4581 60.13 57.3483 60.13 54.7949C60.13 52.2414 62.2398 50.1317 64.7932 50.1317C67.3467 50.1317 69.4564 52.2414 69.4564 54.7949C69.4564 57.3483 67.3467 59.4581 64.7932 59.4581Z"
        fill="white"
      />
      <path
        d="M130.082 83.7753C130.082 81.7772 129.304 79.8889 127.861 78.4461L82.7837 33.2576C81.1186 31.5925 78.8973 30.815 76.6769 31.0372L50.4742 33.7021C46.9209 34.0351 44.0346 36.9215 43.7015 40.4748L41.0372 66.6775C40.8149 68.8979 41.5925 71.2299 43.2576 72.7843L59.0236 88.5503C55.5819 93.5464 53.4722 99.5423 53.4722 105.982C53.4722 122.747 67.1285 136.515 84.005 136.515C100.882 136.514 114.427 122.858 114.427 105.982C114.427 104.872 114.316 103.762 114.204 102.651L127.861 88.9948C129.304 87.6627 130.081 85.7746 130.081 83.7765L130.082 83.7753ZM84.0048 131.073C70.1263 131.073 58.9124 119.86 58.9124 105.981C58.9124 92.1022 70.1261 80.8886 84.0048 80.8886C97.8836 80.8886 109.097 92.2129 109.097 105.981C109.097 119.749 97.7729 131.073 84.0048 131.073ZM124.086 85.2187L112.983 96.4323C108.875 84.3304 97.4396 75.559 84.0048 75.559C75.6777 75.559 68.0165 79.0007 62.5763 84.4415L47.032 68.8979C46.5882 68.4541 46.3659 67.7873 46.3659 67.1213L49.0308 40.9186C49.1415 39.9195 49.9191 39.1419 50.9182 39.0312L77.1209 36.3663H77.3431C77.8984 36.3663 78.4537 36.5886 78.8975 37.0323L124.086 82.2209C124.53 82.6647 124.752 83.1092 124.752 83.7753C124.751 84.4413 124.418 84.8859 124.085 85.2189L124.086 85.2187Z"
        fill="white"
      />
      <path
        d="M85.6665 95.6529C87.5539 95.6529 89.3305 96.4305 90.8849 97.8733C91.884 98.7616 93.4383 98.7616 94.3266 97.7626C95.2148 96.7635 95.2149 95.2091 94.2158 94.3209C91.7732 91.9889 88.7752 90.7676 85.6664 90.7676C80.0042 90.7676 75.1189 94.8754 73.2315 100.538H70.4557C69.4567 100.538 68.5684 101.427 68.5684 102.426C68.5684 103.425 69.4567 104.313 70.4557 104.313H72.4539C72.4539 104.868 72.3431 105.424 72.3431 105.978C72.3431 106.533 72.4539 107.089 72.4539 107.643H70.4557C69.4567 107.643 68.5684 108.532 68.5684 109.531C68.5684 110.53 69.4567 111.418 70.4557 111.418H73.2315C75.1188 117.08 80.0042 121.189 85.6664 121.189C88.7752 121.189 91.8838 119.967 94.2158 117.635C95.2149 116.747 95.2149 115.193 94.3266 114.194C93.4382 113.195 91.8839 113.195 90.8849 114.083C89.4413 115.527 87.5538 116.303 85.6665 116.303C82.6686 116.303 80.0043 114.305 78.4501 111.418H85.5559C86.555 111.418 87.4433 110.53 87.4433 109.531C87.4433 108.531 86.555 107.643 85.5559 107.643H77.2288C77.118 107.088 77.118 106.533 77.118 105.978C77.118 105.423 77.2288 104.867 77.2288 104.313H85.5559C86.555 104.313 87.4433 103.425 87.4433 102.426C87.4433 101.426 86.555 100.538 85.5559 100.538H78.4501C80.0045 97.651 82.6694 95.6527 85.6665 95.6527V95.6529Z"
        fill="white"
      />
    </>
  ),
})
