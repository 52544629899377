import { Box } from '@chakra-ui/react'
import { LoadingSkeletonCard } from '../../cards'

interface LoadingSkeletonProps {
  isVisible: boolean
  numberOfPlaceholders: number
}

export function LoadingSkeleton({ isVisible, numberOfPlaceholders }: LoadingSkeletonProps) {
  return (
    <>
      {isVisible
        ? [...Array(numberOfPlaceholders)].map((_, index) => (
            <Box key={`${index}-placeholder`} h="full">
              <LoadingSkeletonCard />
            </Box>
          ))
        : null}
    </>
  )
}
