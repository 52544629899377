import { Box, Divider, Flex, Show, Text } from '@chakra-ui/react'
import type { AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import type { Product } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'
import { neueMachina } from '../../../../../fonts'
import { SalesTypeIcon } from '../../../icons/sales-type'

interface AvailabilityProps {
  product: Product
  auctionWithHighestBid?: AuctionWithHighestBid | undefined
}

type Availability =
  | 'available-soon'
  | 'available'
  | 'not-available'
  | 'auction-starting-soon'
  | 'auction-in-progress'
  | 'auction-finished'
  | 'sold-out'

export function Availability({ product, auctionWithHighestBid }: AvailabilityProps) {
  const { t } = useTranslation('common')

  const availability = getAvailability(product, auctionWithHighestBid)
  return (
    <Flex w="full" align="center" justify="space-between" px={1} py={1} flexGrow={0}>
      <Flex w="full" align="center" justify="space-between">
        <Text
          as="span"
          mr={2}
          fontFamily={neueMachina.style.fontFamily}
          fontSize="xs"
          textTransform="uppercase"
        >
          {t(availability)}
        </Text>
        <Show above="md">
          <Box flexShrink={1} flexGrow={1} flexBasis="min-content">
            <Divider size="1" borderColor="#000" opacity={100} />
          </Box>
          <Box m={0} ml={2} p={0}>
            <SalesTypeIcon salesType={auctionWithHighestBid ? 'auction' : 'standard'} size="sm" />
          </Box>
        </Show>
      </Flex>
    </Flex>
  )
}

function getAvailability(
  product: Product,
  auctionWithHighestBid: AuctionWithHighestBid | undefined
): Availability {
  const now = new Date().toISOString()
  if (product.defaultVariant?.onHand === 0) {
    return 'sold-out'
  }
  if (auctionWithHighestBid) {
    if (now < auctionWithHighestBid.startedAt) {
      return 'auction-starting-soon'
    } else if (now > auctionWithHighestBid.startedAt && now < auctionWithHighestBid.finishedAt) {
      return 'auction-in-progress'
    } else if (now > auctionWithHighestBid.finishedAt) {
      return 'auction-finished'
    }
    return 'available'
  } else {
    return 'available'
  }
}
