import { Flex, Heading, Stack, Text } from '@chakra-ui/react'
import type { AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import { calculateAuctionState } from '@twlvxtwlv/supabase'
import { toLocalizedDateTime, toLocalizedEuro } from '@twlvxtwlv/utils/src/localization'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { Countdown } from '../../../../countdown'

interface AuctionStatisticsProps {
  auctionWithHighestBid: AuctionWithHighestBid
}

export function AuctionStatistics({ auctionWithHighestBid }: AuctionStatisticsProps) {
  const { t } = useTranslation('auction')

  const [auctionState, setAuctionState] = useState(
    calculateAuctionState(
      auctionWithHighestBid.startedAt ?? '',
      auctionWithHighestBid.finishedAt ?? ''
    )
  )

  return (
    <Stack flex={0} w="full" py={1}>
      <Flex>
        <Stack
          flexGrow={0}
          flexShrink={1}
          spacing={0}
          align="flex-start"
          fontSize={['sm', 'sm', 'sm']}
          color="txtBlack"
          fontWeight="600"
          w="full"
        >
          {auctionState === 'active' ? (
            <Flex justify="center" align="center" color="txtBlack" gap={1} fontSize="xs">
              <Countdown
                date={auctionWithHighestBid.finishedAt}
                onComplete={() => setAuctionState('finished')}
              />
            </Flex>
          ) : (
            <Text w="full" fontSize="xs" lineHeight="1.35">
              {auctionState === 'ready'
                ? toLocalizedDateTime(auctionWithHighestBid.startedAt, 'medium')
                : t('auction:auction-finished')}
            </Text>
          )}
          <Heading fontSize="xs" fontWeight="400" color="txtDarkGray" w="full">
            {auctionState === 'ready'
              ? t('started-at-label')
              : auctionState === 'active'
              ? t('finished-at-label')
              : ''}
          </Heading>
        </Stack>
        <Stack flexGrow={1} flexShrink={0} spacing={0} align="flex-start">
          <Text w="full" fontWeight="600" color="black" fontSize="xs" textAlign="right">
            {toLocalizedEuro(auctionWithHighestBid.highestBidInEuros * 100)}
          </Text>
          <Heading fontSize="xs" fontWeight="400" color="txtDarkGray" textAlign="right">
            {auctionState === 'ready' ? t('start-price') : t('current-price')}
          </Heading>
        </Stack>
      </Flex>
    </Stack>
  )
}
