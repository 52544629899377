import type { MigrationToken } from '@twlvxtwlv/types'

export function mapTokensWithProductInfo(tokens: any[]): MigrationToken[] {
  if (!tokens || !tokens.length) {
    return []
  }
  return tokens.map((token) => {
    const formattedToken: MigrationToken = {
      name: token.title ?? 'Token',
      tokenId: token.tokenId,
      amount: token.amount,
      smartContractAddress: token.smartContractAddress,
      transactionHash: null,
      transactionStatus: null,
    }
    return formattedToken
  })
}
