import { Flex, Heading, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react'
import type { ProductWithAmount } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'

interface ProductsOwnedStatisticsProps {
  product: ProductWithAmount
}

export function ProductsOwnedStatistics({ product }: ProductsOwnedStatisticsProps) {
  const { t } = useTranslation(['auction', 'nft-details'])
  return (
    <Flex w="full" justify="space-between">
      <SimpleGrid columns={2} spacing={6} alignItems="flex-start" w="full">
        <Stack spacing={0} align="flex-start">
          <Text fontWeight="600" fontSize={['sm', 'sm', 'sm', 'sm', 'sm']} color="black">
            {product.tokenId}
          </Text>
          <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
            {t('nft-details:token-id')}
          </Heading>
        </Stack>
        <VStack spacing={0} align="end">
          <Text fontWeight="600" fontSize={['sm', 'sm', 'sm', 'sm', 'sm']} color="black">
            {product.amount ?? 0}
          </Text>
          <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
            {t('nft-details:amount')}
          </Heading>
        </VStack>
      </SimpleGrid>
    </Flex>
  )
}
