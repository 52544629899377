import { Avatar, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

interface ArtistProps {
  artistName: string
  avatarUrl: string
  showAwardText: boolean
}

export function Artist({ artistName, avatarUrl, showAwardText }: ArtistProps) {
  const { t } = useTranslation('nft-details')
  return (
    <Flex w="full" align="center" gap={2}>
      <Avatar size={['sm']} name={artistName} src={avatarUrl} />
      <Stack align="start" h="full" spacing={0} gap={0.5}>
        <Text fontWeight="bold" color="black" textTransform="uppercase" fontSize="xs" noOfLines={1}>
          {artistName}
        </Text>
        <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
          {showAwardText ? t('award') : t('artist-name')}
        </Heading>
      </Stack>
    </Flex>
  )
}
