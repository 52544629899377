import type { TokenTransferType } from '@twlvxtwlv/types'
import type { SmartContractAddress } from './smart-contract-address'
import { smartContractAddresses } from './smart-contract-address'

const batchAbis = new Map([
  [
    smartContractAddresses.Old,
    'function safeBatchTransferFrom( address from, address to, uint256[] memory ids, uint256[] memory amounts, bytes memory data ) public',
  ],
  [
    smartContractAddresses.New,
    'function safeBatchTransferFrom( address from, address to, uint256[] memory ids, uint256[] memory amounts, bytes memory data ) public',
  ],
])

const singleAbis = new Map([
  [
    smartContractAddresses.Old,
    'function safeTransferFrom(address from,address to,uint256 id,uint256 amount,bytes calldata data) public',
  ],
  [
    smartContractAddresses.New,
    'function safeTransferFrom(address from,address to,uint256 id,uint256 amount,bytes memory data) public',
  ],
])

export function getAbiFunction(
  contractAddress: SmartContractAddress,
  transferType: TokenTransferType
) {
  let abi = null
  if (transferType === 'single') {
    abi = singleAbis.get(contractAddress)
  } else if (transferType === 'batch') {
    abi = batchAbis.get(contractAddress)
  } else {
    throw new Error(`Transfer type ${transferType} is not implemented!`)
  }

  if (!abi) {
    throw new Error(`Could not find ${contractAddress} for ${transferType}.`)
  }

  return [abi]
}
