import { Box } from '@chakra-ui/react'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

interface ProductHeroVideoProps {
  url: string | undefined | null
}

export function ProductVideo({ url }: ProductHeroVideoProps) {
  if (!url || url.trim().length === 0) {
    return null
  }
  return (
    <Box h="full" w="full">
      <ReactPlayer
        height="100%"
        width="100%"
        aspectRatio="1:1"
        playsinline
        muted
        playing
        controls={false}
        url={url}
        loop
      />
    </Box>
  )
}
