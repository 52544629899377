import { Flex, Heading, Text, VStack } from '@chakra-ui/react'
import type { ProductWithAmount } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'

interface AwardOwnedStatistics {
  product: ProductWithAmount
}

export function AwardOwnedStatistics({ product }: AwardOwnedStatistics) {
  const { t } = useTranslation(['auction', 'nft-details'])
  return (
    <Flex w="full" justify="end">
      <VStack spacing={0} align="end">
        <Text fontWeight="600" fontSize={['sm', 'sm', 'sm', 'sm', 'sm']} color="black">
          {`${product.productVariant?.editionNumber}/${product.editionSize}`}
        </Text>
        <Heading fontSize="xs" fontWeight="400" color="txtDarkGray">
          {t('nft-details:award-number')}
        </Heading>
      </VStack>
    </Flex>
  )
}
