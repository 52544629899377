import type { DateTimeFormatOptions } from '@twlvxtwlv/types'

export function toLocalizedDateTime(
  dateInUtc: Date | string | undefined | null,
  dateStyle: DateTimeFormatOptions = 'medium',
  timeStyle: DateTimeFormatOptions = 'short',
  language: 'de' | 'en' = 'de'
): string | null {
  if (!dateInUtc) {
    return null
  }
  let date: Date
  if (typeof dateInUtc === 'string') {
    date = new Date(dateInUtc)
  } else {
    date = dateInUtc
  }

  return new Intl.DateTimeFormat(language, {
    dateStyle,
    timeStyle,
  }).format(date)
}
