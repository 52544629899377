import { useRef } from 'react'
import NextImage from 'next/image'
import { Box, VStack } from '@chakra-ui/react'
import { ProductVideo } from '../../../../video'
import { useIntersectionObserver } from '@twlvxtwlv/hooks'

interface ThumbnailProps {
  imageUrl: string
  imageAlt?: string
  imageSize: string
}

export function Thumbnail({ imageUrl, imageAlt, imageSize }: ThumbnailProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {
    rootMargin: '0px',
    freezeOnceVisible: true,
  })
  const isVisible = !!entry?.isIntersecting

  return (
    <VStack align="flex-start" flex={0} w="full" h="full" spacing={0}>
      <Box position="relative" w="full" h="full">
        {imageUrl.endsWith('.mp4') ? (
          <Box ref={ref} w="full" h="full">
            {isVisible ? <ProductVideo url={imageUrl} /> : null}
          </Box>
        ) : (
          <NextImage
            src={imageUrl}
            alt={imageAlt ?? ''}
            fill
            sizes={imageSize}
            style={{
              objectFit: 'contain',
            }}
          />
        )}
      </Box>
    </VStack>
  )
}
